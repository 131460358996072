import { createBrowserRouter } from "react-router-dom";
import App, { loader as mainLoader } from "../App";
import { Home, loader as homeLoader } from '../Pages/Home'
import { Dashboard } from '../Pages/Dashboard'
import { Services } from "../Pages/Services";

export const router = createBrowserRouter([
    {
        path: '/',
        element: <App />,
        loader: mainLoader,
        children: [
            {
                path: '/',
                element: <Home />,
                loader: homeLoader
            },
            {
                path: '/services',
                element: <Services />,
            },
            {
                path: '/dashboard',
                element: <Dashboard />,
            },
        ]
    },
])