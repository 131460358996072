import {createSlice} from '@reduxjs/toolkit'

export const informationSclice = createSlice({
    name: 'information',
    initialState: {
        orders: [],
        order: {},
        user: {
            balance: null,
            mobile: null,
            published: null,
            telegram_id: null,
            telegram_username: null
        }
    },
    reducers: {
        setUserSate: (state, action) => {
            state.user = action.payload
        },
        setOrdersSate: (state, action) => {
            state.orders = action.payload
            if (action.payload.length > 0) {
                state.order = action.payload[0]
            }
        },
        setSelectedOrder: (state, action) => {
            state.order = state.orders.find(item => item.id === parseInt(action.payload));
        }
    },
})

export const {
    setUserSate,
    setOrdersSate,
    setSelectedOrder
} = informationSclice.actions
export default informationSclice.reducer
