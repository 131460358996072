import { configureStore } from '@reduxjs/toolkit';
import modalReducer from './slices/modals'
import tokenReducer from './slices/token'
import informationReducer from './slices/informations'
export default configureStore({
    reducer: {
        modals: modalReducer,
        token: tokenReducer,
        information: informationReducer
    },
});
