import React from 'react'

export const Header = () => {
    return (
        <>
            <header class="w-full">
                <div class="sm:w-[400px] xsm:w-[300px] h-[50px] mx-auto rounded-b-[50px] bg-[#A884FC] flex justify-center items-center gap-5 drop-shadow-[0_0_7px_#fff]">
                    <a href=""><img src="../Assets/Images/Huge-icon.svg" alt="" /></a>
                    <a href=""><img src="../Assets/Images/Huge-icon(1).svg" alt="" /></a>
                    <a href=""><img src="../Assets/Images/Huge-icon(2).svg" alt="" /></a>
                    <a href=""><img src="../Assets/Images/Huge-icon(3).svg" alt="" /></a>
                </div>
            </header>
        </>
    )
}
