import React, {useEffect, useState} from 'react'
import {PieChart} from '../Components/Charts/PieChart'
import {motion} from 'framer-motion'
import {useDispatch, useSelector} from 'react-redux'
import {
    openChangeLocationModal,
    openChangeNameModal,
    openGetLinkModal, openIBsngChangeModal,
    openRenewSubscriptionModal,
    openSubscriptionModal, openUserHistoryModal, openUserOnlineModal
} from '../Redux/slices/modals'
import toast from "react-hot-toast";
import {Link, useNavigate} from "react-router-dom";
import {setOrdersSate, setSelectedOrder, setUserSate} from "../Redux/slices/informations";
import axiosInstance from "../Utils/AxiosInstance";
import {SubmitChangeProtocol} from '../Components/Modals/SubmitChangeProtocol'

export const Dashboard = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = useSelector((state) => state.token.token);
    const information = useSelector((state) => state.information);
    const [confirmChangeProtocol, setConfirmChangeProtocol] = useState(false)
    const [changeProtocolValue, setChangeProtocolValue] = useState(false)

    const [loadingAlarm, setLoadingAlarm] = useState(false);
    const [loadingProtocol, setLoadingProtocol] = useState(false);

    const fetchData = async () => {
        try {
            const response = await axiosInstance.get('/information/general');
            if (response.status === 200) {
                dispatch(setOrdersSate(response.data.orders));
                dispatch(setUserSate(response.data.user));
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                toast.error('توکن دسترسی شما منقضی یا تغییر یافته است.');
            } else {
                toast.error('خطایی رخ داده است، با پشتیبانی تماس بگیرید.');
            }
        }
    };

    useEffect(() => {
        fetchData();
    }, [token, navigate]);

    const sendActionRequest = async (action, data, setLoading) => {
        setLoading(true);
        try {
            const response = await axiosInstance.post('/action', {action, data, "service_id": information?.order?.id});
            if (response.status === 200) {
                toast.success('تغییر شما با موفقیت ذخیره گردید.');
            }
        } catch (error) {
            toast.error('متاسفانه خطایی در هنگام ارسال درخواست شما رخ داده است، مجدد تلاش نمایید.');
        } finally {
            setLoading(false);
        }
    };

    const handleAlarmChange = (e) => {
        sendActionRequest('alarm', {value: e.target.checked}, setLoadingAlarm);
    };

    const handleProtocolChange = (e) => {
        setChangeProtocolValue(e.target.checked)
        setConfirmChangeProtocol(true)

    };

    console.log(information);

    return (
        <>
            <SubmitChangeProtocol status={confirmChangeProtocol} close={() => {
                setConfirmChangeProtocol(false)
                setChangeProtocolValue(prev => !prev)
            }} onConfirm={() => {
                sendActionRequest('protocol', {value: changeProtocolValue}, setLoadingProtocol)
                setConfirmChangeProtocol(false)
            }}/>
            <main
                className="py-20 xl:px-14 md:px-12 lg:px-12 max-md:px-10 max-sm:px-5 flex flex-col items-center sm:gap-14 xsm:gap-7">

                <div className="w-full flex flex-col items-center max-sm:gap-5 sm:gap-10">
                    <p className="text-white md:text-[48px] xsm:text-2xl font-['mukta'] font-bold">Welcome To
                        Dashboard !</p>
                    <p className="text-white md:text-[36px] xsm:text-xl  font-bold">! به پنل کاربری خوش
                        آمدید</p>
                </div>

                <div className="w-full h-px bg-[#292D31]"></div>

                <section className="w-full flex flex-col gap-5 max-sm:gap-3 ">
                    <div
                        className="w-full flex justify-between items-center p-5 max-md:p-3 max-sm:p-2 md:mb-5 bg-[#262431] rounded-md">
                        <select
                            onChange={(e) => (dispatch(setSelectedOrder(e.target.value)))}
                            className="bg-[#211E1E] text-white max-sm:text-sm p-3 max-sm:p-2 w-80 max-lg:w-64 max-sm:w-2/5 rounded-md"
                        >
                            {information.orders.map((value) => {
                                return (<option value={value.id}>{value.name}</option>)
                            })}
                        </select>
                        <div className="flex gap-5 max-sm:gap-2 items-center">
                            <p className="text-white text-xl max-lg:text-base max-sm:text-sm text-right">: سرویس انتخاب
                                شده</p>
                            <img src="/Assets/Images/universal.svg" alt=""/>
                        </div>
                    </div>
                    <div className="w-full flex max-lg:flex-col-reverse gap-5 max-sm:gap-3 flex-row">
                        <div className="w-4/6 max-lg:w-full flex flex-col gap-5 max-sm:gap-3">
                            <div className="w-full flex max-sm:flex-col gap-5 max-sm:gap-3">
                                <div
                                    className="w-3/4 max-sm:w-full flex max-sm:flex-col-reverse bg-[#262431] rounded-md p-5 max-md:p-3">
                                    <div className="w-[45%] max-sm:w-full max-sm:h-40 relative text-right">
                                        <img src="/Assets/Images/BG-export.png" alt=""
                                             className="w-full h-full object-contain absolute z-10"/>
                                        <div
                                            className="w-full h-full flex flex-col gap-5 max-sm:gap-3 justify-end items-center absolute z-20">
                                            <div className="w-full flex justify-around">
                                                <p className="text-white max-md:text-sm max-sm:text-base">
                                                    {
                                                        information?.order?.enable ? (
                                                            "فعال"
                                                        ) : (
                                                            "غیرفعال"
                                                        )
                                                    }
                                                </p>
                                                <p className="text-white flex gap-3 max-md:text-sm max-sm:text-base">
                                                    : وضیعت سرویس
                                                    <img src="/Assets/Images/driver2.svg" alt=""/>
                                                </p>
                                            </div>
                                            <div className="w-full flex justify-around">
                                                <p className="text-white max-md:text-sm max-sm:text-base">
                                                    {
                                                        information?.order?.service?.types == 'v2ray' && (
                                                            "تک لینک"
                                                        )
                                                    }
                                                    {
                                                        information?.order?.service?.types == 'panel' && (
                                                            "مولتی لوکیشن"
                                                        )
                                                    }
                                                    {
                                                        information?.order?.service?.types == 'ibsng' && (
                                                            "Openvpn l2tp"
                                                        )
                                                    }
                                                </p>
                                                <p className="text-white flex gap-3 max-md:text-sm max-sm:text-base">
                                                    : نوع سرویس
                                                    <img src="/Assets/Images/driver2.svg" alt=""/>
                                                </p>
                                            </div>
                                            {information.order?.service?.types == 'v2ray' && (
                                                <button
                                                    onClick={() => dispatch(openChangeLocationModal())}
                                                    className="w-4/5 bg-[#F97316] rounded-md flex justify-center items-center text-white gap-3 py-3">
                                                    تغییر لوکیشن
                                                    <img src="/Assets/Images/routing.svg" alt=""/>
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                    <div className="w-[55%] max-sm:w-full flex flex-col gap-5 max-md:gap-3 items-end">
                                        <div className="flex items-center xl:gap-5 lg:gap-2 sm:gap-2 xsm:gap-2">
                                            <p className="text-[#F97316] xl:text-2xl lg:text-lg md:text-lg sm:text-base">مشخصات
                                                سرویس</p>
                                            <div
                                                className="w-10 h-10 max-md:w-7 max-md:h-7 flex items-center justify-center rounded-full bg-[#211E1E]">
                                                <img src="/Assets/Images/global-search.svg" alt=""
                                                     className="w-8 h-8 max-md:w-5 max-md:h-5"/>
                                            </div>
                                        </div>
                                        <div className="flex items-center gap-3">
                                            <p className="text-white opacity-90 lg:text-sm md:text-xs">
                                                {information?.order?.name}
                                            </p>
                                            <p className="text-white text-xl max-xl:text-sm max-md:text-sm">: نام
                                                سرویس</p>
                                            <img src="/Assets/Images/edit.svg" alt=""
                                                 className="max-md:w-5 max-md:h-5"/>
                                        </div>
                                        {
                                            information?.order?.service?.types != 'panel' && (
                                                <div className="flex items-center gap-3">
                                                    <p className="text-white opacity-90 text-xs">

                                                        {information?.order?.country}
                                                    </p>
                                                    <p className="text-white text-xl max-xl:text-sm max-md:text-sm">: لوکیشن
                                                        سرویس </p>
                                                    <img src="/Assets/Images/location.svg" alt=""
                                                         className="max-md:w-5 max-md:h-5"/>
                                                </div>
                                            )
                                        }

                                    </div>
                                </div>

                                <div
                                    className="w-1/4 max-sm:w-full bg-[#262431] rounded-md p-5 max-md:p-3 flex flex-col justify-center items-center">

                                    <PieChart
                                        max={information?.order?.service?.user_count}
                                        percent={0}
                                        title={"کاربر"}
                                        size={147}
                                        gapDegree={180}
                                        strokeColor={"#7AD3FF"}
                                        trailColor={"rgba(122, 211, 255,0.3)"}
                                        strokeWidth={8}
                                        titleSize={"text-2xl -mt-10"}
                                        type={'users'}
                                    />
                                </div>
                            </div>

                            {information.order?.service?.types == 'v2ray' && (
                                <div
                                    className="w-full bg-[#262431] rounded-md p-5 max-md:p-3 flex flex-col gap-5 max-sm:gap-3 items-end ">
                                    <div className="flex gap-5 max-sm:gap-3 items-center">
                                        <p className="text-[#F97316] xl:text-2xl lg:text-xl md:text-xl">پروتکل سرویس</p>
                                        <div
                                            className="w-10 h-10 flex items-center justify-center rounded-full bg-[#211E1E]">
                                            <img src="/Assets/Images/shield-tick.svg" alt="" className="w-8 h-8"/>
                                        </div>
                                    </div>
                                    <div className="w-full flex max-sm:flex-col-reverse justify-between max-sm:gap-3">
                                        <div className="w-44 max-sm:w-full flex max-sm:items-center gap-2">
                                            <p className="text-white text-sm">Vmess</p>
                                            <div className="flex">
                                                <input
                                                    type="checkbox"
                                                    hidden
                                                    id="check"
                                                    className="peer hidden transition-all duration-100 ease-in-out"
                                                    onChange={handleProtocolChange}
                                                    disabled={loadingProtocol}
                                                    checked={changeProtocolValue}
                                                />
                                                <label
                                                    htmlFor="check"
                                                    className="w-[40px] h-[20px] bg-[#211E1E] border-[1px] border-solid border-[#A884FC] rounded-[100px] relative content-none before:transition-all before:duration-100 before:ease-in-out before:w-[15px] before:h-[15px] before:bg-[#000000] before:absolute before:left-[2px] before:bottom-[2px] before:rounded-full cursor-pointer peer-checked:before:bg-[#A884FC] peer-checked:before:left-[22px]"
                                                ></label>
                                            </div>
                                            <p className="text-white text-sm">Vless</p>
                                        </div>
                                        <p className="text-white xl:text-base lg:text-sm md:text-[13px] max-sm:text-xs text-right">
                                            شما می توانید آموزش اتصال به هر پروتکل را در بخش راهنمای اتصال را در{' '}
                                            <Link to="" className="text-[#A884FC]">
                                                چنل تلگرام
                                            </Link>{' '}
                                            مشاهده کنید
                                        </p>
                                    </div>
                                </div>

                            )}
                            <div
                                className="w-full bg-[#262431] rounded-md p-5 max-md:p-3 flex flex-col gap-5 max-sm:gap-3 items-end  relative content-none before:w-full before:h-full before:bg-black before:filter
                              before:blur-[2px] before:absolute before:top-0 before:left-0 before:opacity-20">
                                <div className="w-full flex max-sm:flex-col-reverse gap-5 items-center justify-between">
                                    <div
                                        className="w-[45%] max-sm:w-full h-[40px] bg-[#2D3545] rounded-full flex items-start">

                                        <motion.div
                                            initial={{width: 0}}
                                            animate={{width: ((information.order?.days / information.order?.total_day) * 100) + "%"}}
                                            className="h-full bg-[#50BBF0] rounded-full"
                                        >
                                        </motion.div>

                                    </div>
                                    <div
                                        className="w-[55%] max-sm:w-full items-center flex gap-5 max-sm:gap-3 justify-end text-right">
                                        <p className="text-[#F97316] xl:text-xl lg:text-sm md:text-base">
                                            مقدار
                                            {` ${information.order?.days} `}
                                            روز
                                            از
                                            {` ${information.order?.total_day} `}
                                            روز باقی مانده است
                                        </p>
                                        <div
                                            className="w-10 h-10 flex items-center justify-center rounded-full bg-[#211E1E]">
                                            <img src="/Assets/Images/timer.svg" alt="" className="w-8 h-8"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex items-center justify-end gap-5 max-sm:gap-3">
                                    <p className="text-white text-xl max-sm:text-lg">تمدید خودکار</p>
                                    <div
                                        className="w-10 h-10 flex items-center justify-center rounded-full bg-[#211E1E]">
                                        <img src="/Assets/Images/calendar-add.svg" alt="" className="w-8 h-8"/>
                                    </div>
                                </div>
                                <div className="w-full flex max-sm:flex-col-reverse justify-between max-sm:gap-3">
                                    <div className="w-44 max-sm:w-full flex max-sm:items-center gap-2">
                                        <p className="text-white text-sm">غیر فعال</p>
                                        <div className="flex">
                                            <input type="checkbox"
                                                   hidden name=""
                                                   id="check2"
                                                   className="peer hidden transition-all duration-100 ease-in-out"
                                                   onChange={(e) => (
                                                       sendActionRequest(
                                                           "auto_pay",
                                                           {"value": e.target.value}
                                                       )
                                                   )}
                                            />
                                            <label
                                                for="check2"
                                                className="w-[40px] h-[20px] bg-[#211E1E] border-[1px] border-solid border-[#A884FC] rounded-[100px] relative content-none before:transition-all before:duration-100 before:ease-in-out before:w-[15px] before:h-[15px] before:bg-[#000000] before:absolute before:left-[2px] before:bottom-[2px] before:rounded-full cursor-pointer peer-checked:before:bg-[#A884FC] peer-checked:before:left-[22px]">
                                            </label>
                                        </div>
                                        <p className="text-white text-sm">فعال</p>
                                    </div>
                                    <p className="text-white xl:text-base lg:text-sm md:text-[13px] max-sm:text-xs text-right">توجه
                                        برای استفاده از این قابلیت باید کیف پول خود را به اندازه لازم برای تمدید سرویس
                                        شارژ نمایید</p>
                                </div>
                            </div>
                        </div>
                        <div
                            className="w-2/6 max-lg:w-full bg-[#262431] rounded-md flex flex-col justify-evenly items-center p-5">

                            <PieChart
                                max={information.order?.total_traffic}
                                percent={information.order?.total / 1024}
                                title={"حجم باقی مانده"}
                                size={290}
                                gapDegree={60}
                                strokeColor={"#7AD3FF"}
                                trailColor={"rgba(122, 211, 255,0.3)"}
                                strokeWidth={14}
                                titleSize={"text-2xl"}
                            />

                            <div className='flex flex-col gap-6 justify-between items-center'>
                                <p className='text-[#FFF1F1]'>{information.order?.service?.name}</p>
                                <p className='text-[#FFF1F1]'>تاریخ خرید : {information.order?.published}</p>
                            </div>


                        </div>
                    </div>

                    <div className="w-full bg-[#262431] rounded-md p-5 max-md:p-3 flex justify-between">
                        <div className="flex gap-5 max-md:gap-3 items-center">
                            <button
                                className="p-2 flex items-center justify-center bg-[#A884FC] rounded-md"
                                onClick={() => {
                                    navigator.clipboard.writeText(information.order?.name);
                                    toast.success('شناسه شما با موفقیت کپی گردید');
                                }}
                            >
                                <img src="/Assets/Images/document-copy.svg" alt=""/>
                            </button>
                            <p className="text-white">{information.order?.name}</p>
                        </div>
                        <div className="flex gap-5 max-md:gap-3 items-center">
                            <p className="text-white text-xl max-md:text-base">: شناسه سرویس</p>
                            <div className="w-10 h-10 flex items-center justify-center rounded-full bg-[#211E1E]">
                                <img src="/Assets/Images/shield-tick.svg" alt="" className="w-8 h-8"/>
                            </div>
                        </div>
                    </div>
                    <div className="w-full bg-[#262431] rounded-md p-5 max-md:p-3 flex justify-between">
                        <div className="flex gap-5 max-md:gap-3 items-center">
                            <button
                                className="p-2 flex items-center justify-center bg-[#A884FC] rounded-md"
                                onClick={() => {
                                    navigator.clipboard.writeText(information.order?.subscribe_id);
                                    toast.success('لایسنس شما با موفقیت کپی گردید');
                                }}
                            >
                                <img src="/Assets/Images/document-copy.svg" alt=""/>
                            </button>
                            <p className="text-white">{information.order?.subscribe_id}</p>
                        </div>
                        <div className="flex gap-5 max-md:gap-3 items-center">
                            <p className="text-white text-xl max-md:text-base">: لایسنس سرویس</p>
                            <div className="w-10 h-10 flex items-center justify-center rounded-full bg-[#211E1E]">
                                <img src="/Assets/Images/hashtag.svg" alt="" className="w-8 h-8"/>
                            </div>
                        </div>
                    </div>
                    <div
                        className="w-full flex flex-row-reverse max-sm:flex-col max flex-wrap justify-between gap-y-[20px] max-sm:gap-3">
                        {information.order?.service?.types == 'v2ray' && (
                            <div
                                className="w-[49%] max-sm:w-full bg-[#262431] rounded-md p-5 max-md:p-3 flex max-md:flex-col-reverse max-md:items-center max-md:gap-2 justify-between">
                                <button

                                    onClick={() => dispatch(openGetLinkModal())}
                                    className="p-2 flex items-center justify-center bg-[#A884FC] rounded-md text-white gap-2 w-36 max-lg:w-[30%] max-lg:text-sm max-md:w-full">
                                    <img src="/Assets/Images/document-copy.svg" alt=""/>
                                    دریافت
                                </button>
                                <div className="flex gap-5 max-lg:gap-3 items-center">
                                    <p className="text-white text-xl max-lg:text-base max-sm:text-xs">دریافت لینک</p>
                                    <div
                                        className="w-10 h-10 max-sm:w-7 max-sm:h-7 flex items-center justify-center rounded-full bg-[#211E1E]">
                                        <img src="/Assets/Images/link.svg" alt=""
                                             className="w-8 h-8 max-sm:w-7 max-sm:h-7"/>
                                    </div>
                                </div>
                            </div>
                        )}
                        {information.order?.service?.types != 'ibsng' && (
                            <div
                                className="w-[49%] max-sm:w-full bg-[#262431] rounded-md p-5 max-md:p-3 flex max-md:flex-col-reverse max-md:items-center max-md:gap-2 justify-between">
                                <button
                                    onClick={() => dispatch(openSubscriptionModal())}
                                    className="p-2 flex items-center justify-center bg-[#A884FC] rounded-md text-white gap-2 w-36 max-lg:w-[30%] max-lg:text-sm max-md:w-full">
                                    <img src="/Assets/Images/document-copy.svg" alt=""/>
                                    دریافت
                                </button>
                                <div className="flex gap-5 items-center">
                                    <p className="text-white text-xl max-lg:text-base max-sm:text-xs">دریافت لینک
                                        اشتراک</p>
                                    <div
                                        className="w-10 h-10 max-sm:w-7 max-sm:h-7 flex items-center justify-center rounded-full bg-[#211E1E]">
                                        <img src="/Assets/Images/link2.svg" alt=""
                                             className="w-8 h-8 max-sm:w-7 max-sm:h-7"/>
                                    </div>
                                </div>
                            </div>
                        )}
                        {information.order?.service?.types != 'ibsng' && (
                            <div
                                className="w-[49%] max-sm:w-full bg-[#262431] rounded-md p-5 max-md:p-3 flex max-md:flex-col-reverse max-md:items-center max-md:gap-2 justify-between">
                                <button
                                    onClick={() => dispatch(openRenewSubscriptionModal())}
                                    className="p-2 flex items-center justify-center bg-[#A884FC] rounded-md text-white gap-2 w-36 max-lg:w-[30%] max-lg:text-sm max-md:w-full">
                                    <img src="/Assets/Images/refresh-2.svg" alt=""/>
                                    تغییر
                                </button>
                                <div className="flex gap-5 items-center">
                                    <p className="text-white text-xl max-lg:text-base max-sm:text-xs">تغییر لینک
                                        اتصال و اشتراک</p>
                                    <div
                                        className="w-10 h-10 max-sm:w-7 max-sm:h-7 flex items-center justify-center rounded-full bg-[#211E1E]">
                                        <img src="/Assets/Images/convert-3d-cube.svg" alt=""
                                             className="w-8 h-8 max-sm:w-7 max-sm:h-7"/>
                                    </div>
                                </div>
                            </div>
                        )}

                        <div
                            className="w-[49%] max-sm:w-full bg-[#262431] rounded-md p-5 max-md:p-3 flex max-md:flex-col-reverse max-md:items-center max-md:gap-2 justify-between">
                            <button
                                onClick={() => dispatch(openChangeNameModal())}
                                className="p-2 flex items-center justify-center bg-[#A884FC] rounded-md text-white gap-2 w-36 max-lg:w-[30%] max-lg:text-sm max-md:w-full">
                                <img src="/Assets/Images/edit.svg" alt=""/>
                                تغییر
                            </button>
                            <div className="flex gap-5 items-center">
                                <p className="text-white text-xl max-lg:text-base max-sm:text-xs">تغییر نام سرویس</p>
                                <div
                                    className="w-10 h-10 max-sm:w-7 max-sm:h-7 flex items-center justify-center rounded-full bg-[#211E1E]">
                                    <img src="/Assets/Images/global-edit.svg" alt=""
                                         className="w-8 h-8 max-sm:w-7 max-sm:h-7"
                                    />
                                </div>
                            </div>
                        </div>

                        {information.order?.service?.types == 'ibsng' && (
                            <div
                                className="w-[49%] max-sm:w-full bg-[#262431] rounded-md p-5 max-md:p-3 flex max-md:flex-col-reverse max-md:items-center max-md:gap-2 justify-between">
                                <button
                                    onClick={() => dispatch(openIBsngChangeModal())}
                                    className="p-2 flex items-center justify-center bg-[#A884FC] rounded-md text-white gap-2 w-36 max-lg:w-[30%] max-lg:text-sm max-md:w-full">
                                    <img src="/Assets/Images/refresh-2.svg" alt=""/>
                                    تغییر
                                </button>
                                <div className="flex gap-5 items-center">
                                    <p className="text-white text-xl max-lg:text-base max-sm:text-xs">ویرایش اطلاعات</p>
                                    <div
                                        className="w-10 h-10 max-sm:w-6 max-sm:h-6 max-lg:w-8 max-lg:h-8 flex items-center justify-center rounded-full bg-[#211E1E]">
                                        <img src="/Assets/Images/refresh-2.svg" alt=""
                                             className="w-8 h-8 max-lg:w-5 max-lg:h-5 max-sm:w-4 max-sm:h-4"/>
                                    </div>
                                </div>
                            </div>
                        )}

                        <div
                            className="w-[49%] max-sm:w-full bg-[#262431] rounded-md p-5 max-md:p-3 flex max-md:flex-col-reverse max-md:items-center max-md:gap-2 justify-between">
                            <button

                                onClick={() => dispatch(openUserOnlineModal())}
                                className="p-2 flex items-center justify-center bg-[#A884FC] rounded-md text-white gap-2 w-36 max-lg:w-[30%] max-lg:text-sm max-md:w-full">
                                <img src="/Assets/Images/eye.svg" alt=""/>
                                مشاهده
                            </button>
                            <div className="flex gap-5 items-center">
                                <p className="text-white text-xl max-lg:text-base max-sm:text-xs">افراد متصل</p>
                                <div
                                    className="w-10 h-10 max-sm:w-7 max-sm:h-7 flex items-center justify-center rounded-full bg-[#211E1E]">
                                    <img src="/Assets/Images/people.svg" alt=""
                                         className="w-8 h-8 max-sm:w-7 max-sm:h-7"/>
                                </div>
                            </div>
                        </div>
                        <div
                            className="w-[49%] max-sm:w-full bg-[#262431] rounded-md p-5 max-md:p-3 flex max-md:flex-col-reverse max-md:items-center max-md:gap-2 justify-between">
                            <button

                                onClick={() => dispatch(openUserHistoryModal())}
                                className="p-2 flex items-center justify-center bg-[#A884FC] rounded-md text-white gap-2 w-36 max-lg:w-[30%] max-lg:text-sm max-md:w-full">
                                <img src="/Assets/Images/eye.svg" alt=""/>
                                مشاهده
                            </button>
                            <div className="flex gap-5 items-center">
                                <p className="text-white text-xl max-lg:text-base max-sm:text-xs">تاریخچه افراد
                                    متصل</p>
                                <div
                                    className="w-10 h-10 max-sm:w-7 max-sm:h-7 flex items-center justify-center rounded-full bg-[#211E1E]">
                                    <img src="/Assets/Images/security-time.svg" alt=""
                                         className="w-8 h-8 max-sm:w-7 max-sm:h-7"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </main>
        </>
    )
}
