import React, { useEffect, useState } from 'react';
import { changeLocationModalStatus, closeChangeLocationModal } from '../../Redux/slices/modals';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import axiosInstance from "../../Utils/AxiosInstance";
import toast from "react-hot-toast";
import ReactLoading from 'react-loading';

export const ChangeService = () => {
    const dispatch = useDispatch();
    const thisModalStatus = useSelector(changeLocationModalStatus);

    const information = useSelector((state) => state.information);
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState({ id: null });
    const [loading, setLoading] = useState(false);

    const fetchData = async () => {
        try {
            const response = await axiosInstance.get('/information/countries');
            if (response.status === 200) {
                setCountries(response.data?.countries || []);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                toast.error('توکن شما معتبر نمی باشد، لطفا توکن خود را بررسی کنید.');
            } else {
                toast.error('خطایی رخ داده است، با پشتیبانی تماس بگیرید.');
            }
        }
    };

    useEffect(() => {
        if (thisModalStatus) fetchData();
    }, [thisModalStatus]);

    const handleConfirm = async () => {
        if (!selectedCountry.id) {
            toast.error('لطفا یک کشور را انتخاب نمایید.');
            return;
        }

        setLoading(true);

        try {
            const response = await axiosInstance.post('/action', {
                action: 'change_location',
                "service_id": information?.order?.id,
                data: { country: selectedCountry.id }
            });
            if (response.status === 200) {
                toast.success(response.data?.message || 'لوکیشن شما با موفقیت تغییر یافت.');
                dispatch(closeChangeLocationModal());
            }
        } catch (error) {
            toast.error(
                error?.response?.data?.message || 'متاسفانه خطایی در هنگام تغییر لوکیشن سرویس شما رخ داد، مجدد تلاش نمایید.'
            );
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <motion.div
                className='w-full h-[100vh] bg-[rgba(0,0,0,.28)] fixed z-50'
                initial={{ opacity: 0 }}
                animate={{ opacity: thisModalStatus ? 1 : 0, display: thisModalStatus ? 'block' : 'none' }}
                onClick={() => dispatch(closeChangeLocationModal())}
            ></motion.div>

            <motion.div
                initial={{top: -4000}}
                animate={{top: thisModalStatus ? '50%' : -4000}}
                className="w-[650px] max-lg:w-[550px] max-sm:w-4/5 p-5 fixed -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 flex flex-col bg-[#262431]  rounded-md gap-5 z-[100]"
            >
                <div className="w-full flex justify-end items-center gap-3 max-sm:gap-1">
                    <p className="text-white text-xl max-md:text-base">تغییر لوکیشن ؟</p>

                </div>
                <img
                    src='../Assets/Images/BG-export.png'
                    alt=''
                    className='absolute top-0 left-0 w-full h-full object-cover z-10'
                />

                <div className="w-full h-[380px] relative z-30 overflow-y-scroll" dir="rtl">
                    <div className="w-full absolute z-20 flex flex-wrap gap-5 max-sm:justify-evenly">
                        {countries.map((e) => (
                            <button
                                key={e.id}
                                onClick={() => setSelectedCountry(e)}
                                className={`w-[180px] max-lg:w-[220px] max-sm:w-[120px] flex justify-between p-3 rounded-md items-center ${selectedCountry.id === e.id ? 'bg-[#A884FC]' : 'bg-[#211E1E]'}`}
                                dir='ltr'
                            >
                                <p className="text-white max-sm:text-xs flex">{e.name}</p>
                                <img src="../Assets/Images/location.svg" alt="" className="max-sm:w-5 max-sm:h-5"/>
                            </button>
                        ))}
                    </div>
                </div>
                <div className="w-full flex justify-evenly pt-5 max-md:pt-2 z-10">
                    <button
                        onClick={() => dispatch(closeChangeLocationModal())}
                        className="w-[220px] max-md:w-[180px] max-sm:w-2/5 p-2 max-md:text-sm max-sm:text-xs bg-[#3C3155] text-white rounded-md"
                    >
                        لغو
                    </button>
                    <button
                        onClick={handleConfirm}
                        className="w-[220px] max-md:w-[180px] max-sm:w-2/6 p-2 max-md:text-sm max-sm:text-xs bg-[#A884FC] text-white rounded-md flex justify-center items-center"
                    >
                        {loading ? <ReactLoading type={"bubbles"} color={"#fff"} height={32} width={32}/> : 'تایید'}
                    </button>
                </div>
            </motion.div>
        </>
    );
};
