import React from 'react'
import { Link } from 'react-router-dom'

export const Footer = () => {
    return (
        <footer class="w-full h-16 bg-[#0D0E14] flex justify-center items-center font-[mukta]">
            <p class="text-white sm:w-64 xsm:w-48">@2024 DEY GROUP</p>
            <div class="flex items-center gap-5">
                <Link to="https://t.me/deyvpn" class="w-7 h-7">
                    <img src="../Assets/Images/Social-Icons.svg" alt="" />
                </Link>
                <Link to="https://instagram.com/deyvpn" class="w-7 h-7">
                    <img src="../Assets/Images/Social-Icons(1).svg" alt="" />
                </Link>
                <Link to="https://x.com/deyvpn" class="w-7 h-7">
                    <img src="../Assets/Images/Social-Icons(2).svg" alt="" />
                </Link>
            </div>
        </footer>
    )
}
