import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {RouterProvider} from 'react-router-dom';
import {router} from './Routes/routes';

import {Provider} from 'react-redux';
import Store from './Redux/store'

import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ["localhost", process.env.REACT_APP_API_URL],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider store={Store}>
            <RouterProvider router={router}/>
        </Provider>
    </React.StrictMode>
);

reportWebVitals();
