import React from 'react'
import { Link } from 'react-router-dom'
import { PieChart } from '../Components/Charts/PieChart'
export const Services = () => {
    return (

        <main class="py-20 px-20 max-md:px-10 max-sm:px-5 flex flex-col items-center gap-14">

            <div class="w-full flex flex-col items-center max-sm:gap-5 sm:gap-10">
                <p class="text-white md:text-[48px] xsm:text-2xl  font-bold">Welcome To Dashboard !</p>
                <p class="text-white md:text-[36px] xsm:text-xl  font-bold">! به پنل کاربری خوش آمدید</p>
            </div>


            {Array(10).fill(<Link to="/dashboard" class="xl:w-4/5 xsm:w-full p-5 rounded-md bg-[#262431] flex lg:flex-row xsm:flex-col-reverse  max-lg:gap-5">

                <div class="lg:w-1/2 xsm:w-full justify-evenly flex gap-5 items-center">

                    <PieChart max={150} percent={90} title={"حجم باقی مانده"} />

                    <PieChart max={150} percent={90} type={true} title={"زمان باقی مانده"} />
                </div>

                <div class="lg:w-1/2 xsm:w-full flex flex-col gap-5 items-end">
                    <div class="flex items-center gap-3">
                        <p class="text-[#F97316] text-xl max-sm:text-base">liara</p>
                        <p class="text-[#F97316] text-2xl max-sm:text-base">:سرویس</p>
                        <div class="w-10 h-10  p-1 bg-[#211E1E]"><img src="/Assets/Images/driver.svg" alt="" /></div>
                    </div>
                    <div class="px-5 flex items-center gap-3">
                        <p class="text-white opacity-90 text-sm">india</p>
                        <p class="text-white text-xl max-sm:text-base">: لوکیشن سرویس </p>
                        <img src="/Assets/Images/location.svg" alt="" />
                    </div>
                    <div class="px-5 flex items-center gap-3">
                        <p class="text-white text-xl max-sm:text-base">تاریخ خرید : 1400/10/01</p>
                        <img src="/Assets/Images/calendar.svg" alt="" />
                    </div>
                </div>
            </Link>)}

        </main>
    )
}
