import React from 'react'
import { AnimatePresence, motion } from "framer-motion";
import { createPortal } from 'react-dom';
export const SubmitChangeProtocol = ({ status, close, onConfirm }) => {

    return createPortal(
        <AnimatePresence>
            {status && (
                <>
                    <motion.div
                        className='w-full h-[100vh] bg-[rgba(0,0,0,.28)] fixed z-[100] top-0'
                        initial={{
                            opacity: 0
                        }}
                        animate={{
                            opacity: 1,
                        }}
                        onClick={close}
                        exit={{ opacity: 0 }}
                    ></motion.div>

                    <motion.div
                        initial={{
                            top: -4000,
                        }}
                        animate={{
                            top: '50%'
                        }}
                        exit={{ top: -4000 }}
                        className='fixed -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 bg-[#262431] h-[200px] xsm:w-[80%] md:w-[38%] flex flex-col z-[101] rounded-md justify-between'>
                        <div className='flex gap-2 items-center flex-row-reverse p-5'>
                            <img src='/Assets/Images/alert.svg' className='w-[50px] h-[45px]' alt='' />
                            <div className='text-white font-black text-2xl'>آیا از تغیر لینک اتصال مطمئنید ؟</div>
                        </div>

                        <div className='flex justify-between p-5'>
                            <button
                                onClick={close}
                                className='bg-[#3C3155] w-[45%] p-2 rounded-md text-white text-lg'
                            >لغو</button>
                            <button
                                onClick={onConfirm}
                                className='bg-[#A884FC] w-[45%] p-2 rounded-md text-white text-lg'>بله مطمئنم</button>
                        </div>

                    </motion.div>


                </>
            )}
        </AnimatePresence>
        ,
        document.body
    )
}
