import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { AnimatePresence, motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { changeNameModalStatus, closeChangeNameModal } from "../../Redux/slices/modals";
import axiosInstance from "../../Utils/AxiosInstance";
import toast from "react-hot-toast";

export const ChangeName = () => {
    const thisModalStatus = useSelector(changeNameModalStatus);
    const information = useSelector((state) => state.information);
    const dispatch = useDispatch();

    const [name, setName] = useState(information.order?.name || '');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handleNameChange = (e) => {
        setName(e.target.value);
        setError(''); // Clear error message on input change
    };

    const handleSubmit = async () => {
        if (name.trim() === '') {
            setError('لطفا نام سرویس خود را وارد نمایید.');
            return;
        }

        setLoading(true);

        try {
            const response = await axiosInstance.post('/action', {
                action: 'change_name',
                "service_id": information?.order?.id,
                data: { name }
            });

            if (response.status === 200) {
                toast.success(response.data?.message || 'نام سرویس با موفقیت تغییر یافت.');
                dispatch(closeChangeNameModal());
            }
        } catch (error) {
            toast.error(
                error?.response?.data?.message || "متاسفانه در هنگام تغییر نام خطایی رخ داد، مجدد تلاش نمایید."
            );
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        if (!name) setName(information.order?.name)
    }, [information.order])

    return (
        <AnimatePresence>
            {thisModalStatus && (
                <>
                    <motion.div
                        className='w-full h-full bg-[rgba(0,0,0,.28)] fixed top-0 left-0 z-50'
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        onClick={() => dispatch(closeChangeNameModal())}
                    ></motion.div>

                    <motion.div
                        initial={{ top: '-50%' }}
                        animate={{ top: '50%' }}
                        exit={{ top: '-50%' }}
                        className='fixed left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#262431] h-auto xsm:w-[80%] md:w-[38%] flex flex-col z-[101] rounded-md justify-between font-["vazirmatn"]'
                    >
                        <div className='flex gap-2 items-center flex-row-reverse p-5'>
                            <div className='text-white font-black text-xl' dir='rtl'>
                                جهت تغییر نام سرویس، نام مورد نظر خود را وارد کنید:
                            </div>
                        </div>

                        {error && (
                            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mx-5 mb-3"
                                role="alert">
                                <span className="block sm:inline">{error}</span>
                                <span
                                    className="absolute top-0 bottom-0 right-0 px-4 py-3"
                                    onClick={() => setError('')}
                                >
                                    <svg className="fill-current h-6 w-6 text-red-500" role="button"
                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <title>Close</title>
                                        <path
                                            d="M14.348 5.652a1 1 0 010 1.414L11.414 10l2.934 2.934a1 1 0 11-1.414 1.414L10 11.414l-2.934 2.934a1 1 0 01-1.414-1.414L8.586 10 5.652 7.066a1 1 0 011.414-1.414L10 8.586l2.934-2.934a1 1 0 011.414 0z" />
                                    </svg>
                                </span>
                            </div>
                        )}

                        <div className='flex gap-2 items-center flex-col-reverse p-5'>
                            <input
                                type="text"
                                placeholder="نام سرویس دلخواه خود را وارد نمایید ..."
                                value={name}
                                onChange={handleNameChange}
                                className="bg-[#211E1E] w-full p-3 rounded-md outline-none text-white"
                            />
                        </div>

                        <div className='flex gap-2 items-center flex-row-reverse p-5'>
                            <img src='/Assets/Images/alert.svg' className='w-[24px] h-[24px]' alt='' />
                            <div className='text-white font-black text-sm'>توجه: نام ارسالی فقط باید اعداد و حروف انگلیسی باشد
                            </div>
                        </div>

                        <div className='flex justify-between p-5'>
                            <button
                                onClick={() => dispatch(closeChangeNameModal())}
                                className='bg-[#3C3155] w-[45%] p-2 rounded-md text-white text-lg'
                            >
                                لغو
                            </button>
                            <button
                                onClick={handleSubmit}
                                className='bg-[#A884FC] w-[45%] p-2 rounded-md text-white text-lg flex justify-center items-center'
                                disabled={loading}
                            >
                                {loading ?
                                    <ReactLoading type={"bubbles"} color={"#fff"} height={32} width={32} /> : 'تایید و تغییر'}
                            </button>
                        </div>
                    </motion.div>

                </>
            )}
        </AnimatePresence>
    );
};
