import React, { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import {
    closeUserOnlineModal,
    userOnlineStatus
} from "../../Redux/slices/modals";
import { FaClipboard } from 'react-icons/fa';

export const UserOnline = () => {
    const thisModalStatus = useSelector(userOnlineStatus);
    const dispatch = useDispatch();

    const [subscriptionLinks] = useState([
        { id: 1, url: 'https://sub.unknownvpn.io/sub/xVv7NTRu1vultnuWAWdzXE74Mfggylf' },
        { id: 2, url: 'https://sub.unknownvpn.io/sub2/xVv7NTRu1vultnuWAWdzXE74Mfggylf' }
    ]);

    const handleCopy = (url) => {
        navigator.clipboard.writeText(url);
        alert('Link copied to clipboard');
    };

    return (
        <AnimatePresence>
            {thisModalStatus && (
                <>
                    <motion.div
                        className='w-full h-full bg-[rgba(0,0,0,.28)] fixed top-0 left-0 z-50'
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        onClick={() => dispatch(closeUserOnlineModal())}
                    ></motion.div>

                    <motion.div
                        initial={{ top: '-50%' }}
                        animate={{ top: '50%' }}
                        exit={{ top: "-50%" }}
                        className='fixed left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#262431] h-auto xsm:w-[80%] md:w-[38%] flex flex-col z-[101] rounded-md p-6'
                    >
                        <div className='text-white font-black text-xl mb-4 text-center'>
                            لیست افراد متصل به این سرویس
                        </div>
                        <div className='text-white text-sm mb-4 text-center' dir='rtl'>
                            هیچ ip متصل نمی باشد
                        </div>

                        <button
                            onClick={() => dispatch(closeUserOnlineModal())}
                            className='bg-[#2e74ec] w-full p-2 rounded-md text-white text-lg mt-4'
                        >
                            بازگشت
                        </button>
                    </motion.div>
                </>
            )}

        </AnimatePresence>
    );
};
