import React, {useState} from 'react';
import ReactLoading from 'react-loading';
import {AnimatePresence, motion} from 'framer-motion';
import {useDispatch, useSelector} from 'react-redux';
import {closeRenewSubscriptionModal, renewSubscriptionStatus, closeSubmitModal} from '../../Redux/slices/modals';
import axiosInstance from "../../Utils/AxiosInstance";
import toast from "react-hot-toast";

export const ReNewLink = () => {
    const thisModalStatus = useSelector(renewSubscriptionStatus);
    const dispatch = useDispatch();

    const information = useSelector((state) => state.information);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handleRenew = async () => {
        setLoading(true);
        setError('');

        try {
            const response = await axiosInstance.post('/action', {
                action: 'renew_link',
                "service_id": information?.order?.id,
            });

            if (response.status === 200) {
                toast.success("لینک شما با موفقیت ریست گردید.");
                dispatch(closeSubmitModal());
                dispatch(closeRenewSubscriptionModal());
            }
        } catch (error) {
            toast.error(
                error?.response?.data?.message || "متاسفانه خطایی رخ داده است، محدد تلاش نمایید."
            );
        } finally {
            setLoading(false);
        }
    };

    return (
        <AnimatePresence>
            {thisModalStatus && (
                <>
                    <motion.div
                        className='w-full h-[100vh] bg-[rgba(0,0,0,.28)] fixed z-50'
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0}}
                        onClick={() => dispatch(closeRenewSubscriptionModal())}
                    ></motion.div>

                    <motion.div
                        initial={{top: -4000}}
                        animate={{top: '50%'}}
                        exit={{top: -4000}}
                        className='fixed -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 bg-[#262431] h-[200px] xsm:w-[80%] md:w-[38%] flex flex-col z-[101] rounded-md justify-between'
                    >
                        <div className='flex gap-2 items-center flex-row-reverse p-5'>
                            <img src='/Assets/Images/alert.svg' className='w-[50px] h-[45px]' alt=''/>
                            <div className='text-white font-black text-2xl'>آیا از تغییر لینک اشتراک مطمئنید ؟</div>
                        </div>

                        <div className='flex gap-2 pr-5 text-white text-sm mb-4 text-right' dir='rtl'>
                            با انجام این کار لینک اتصال شما تغییر خواهد کرد و باید لینک جدید را وارد برنامه خود کنید.
                        </div>
                        {error && (
                            <div
                                className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mx-5 mb-3"
                                role="alert">
                                <span className="block sm:inline">{error}</span>
                                <span className="absolute top-0 bottom-0 right-0 px-4 py-3"
                                      onClick={() => setError('')}>
                                    <svg className="fill-current h-6 w-6 text-red-500" role="button"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <title>Close</title>
                                        <path
                                            d="M14.348 5.652a1 1 0 010 1.414L11.414 10l2.934 2.934a1 1 0 11-1.414 1.414L10 11.414l-2.934 2.934a1 1 0 01-1.414-1.414L8.586 10 5.652 7.066a1 1 0 011.414-1.414L10 8.586l2.934-2.934a1 1 0 011.414 0z"/>
                                    </svg>
                                </span>
                            </div>
                        )}

                        <div className='flex justify-between p-5'>
                            <button
                                onClick={() => dispatch(closeRenewSubscriptionModal())}
                                className='bg-[#3C3155] w-[45%] p-2 rounded-md text-white text-lg'
                            >
                                لغو
                            </button>
                            <button
                                onClick={handleRenew}
                                className='bg-[#A884FC] w-[45%] p-2 rounded-md text-white text-lg flex justify-center items-center'
                                disabled={loading}
                            >
                                {loading ?
                                    <ReactLoading type={"bubbles"} color={"#fff"} height={32}
                                                  width={32}/> : 'بله مطمئنم'}
                            </button>
                        </div>
                    </motion.div>
                </>
            )}

        </AnimatePresence>
    );
};
