import {createSlice} from '@reduxjs/toolkit'

export const modalsSclice = createSlice({
    name: 'modals',
    initialState: {
        submitModalStatus: false,
        getLinkModalStatus: false,
        subscriptionStatus: false,
        changeNameModalStatus: false,
        changeLocationModalStatus: false,
        renewSubscriptionStatus: false,
        userOnlineStatus: false,
        userHistoryStatus: false,
        IBsngChangeStatus: false,
    },
    reducers: {
        closeSubmitModal: (state, action) => {
            state.submitModalStatus = false
        },
        openSubmitModal: (state, action) => {
            state.submitModalStatus = true
            state.changeLocationModalStatus = false
        },
        closeSubscriptionModal: (state, action) => {
            state.subscriptionStatus = false
        },
        openSubscriptionModal: (state, action) => {
            state.subscriptionStatus = true
        },
        closeGetLinkModal: (state, action) => {
            state.getLinkModalStatus = false
        },
        openGetLinkModal: (state, action) => {
            state.getLinkModalStatus = true
        },
        closeChangeNameModal: (state, action) => {
            state.changeNameModalStatus = false
        },
        openChangeNameModal: (state, action) => {
            state.changeNameModalStatus = true
        },
        closeChangeLocationModal: (state, action) => {
            state.changeLocationModalStatus = false
        },
        openChangeLocationModal: (state, action) => {
            state.submitModalStatus = false
            state.changeLocationModalStatus = true
        },
        closeRenewSubscriptionModal: (state, action) => {
            state.renewSubscriptionStatus = false
        },
        openRenewSubscriptionModal: (state, action) => {
            state.renewSubscriptionStatus = true
        },
        closeIBsngChangeModal: (state, action) => {
            state.IBsngChangeStatus = false
        },
        openIBsngChangeModal: (state, action) => {
            state.IBsngChangeStatus = true
        },
        closeUserHistoryModal: (state, action) => {
            state.userHistoryStatus = false
        },
        openUserHistoryModal: (state, action) => {
            state.userHistoryStatus = true
        },
        closeUserOnlineModal: (state, action) => {
            state.userOnlineStatus = false
        },
        openUserOnlineModal: (state, action) => {
            state.userOnlineStatus = true
        },
    },
})

export const {
    closeIBsngChangeModal,
    openIBsngChangeModal,
    openUserHistoryModal,
    closeUserHistoryModal,
    openUserOnlineModal,
    closeUserOnlineModal,
    closeSubmitModal,
    openSubmitModal,
    closeRenewSubscriptionModal,
    openRenewSubscriptionModal,
    openSubscriptionModal,
    closeSubscriptionModal,
    openGetLinkModal,
    closeGetLinkModal,
    closeChangeNameModal,
    openChangeNameModal,
    openChangeLocationModal,
    closeChangeLocationModal
} = modalsSclice.actions
export const submitModalStatus = (state) => state.modals.submitModalStatus
export const IBsngChangeStatus = (state) => state.modals.IBsngChangeStatus
export const userHistoryStatus = (state) => state.modals.userHistoryStatus
export const userOnlineStatus = (state) => state.modals.userOnlineStatus
export const subscriptionStatus = (state) => state.modals.subscriptionStatus
export const renewSubscriptionStatus = (state) => state.modals.renewSubscriptionStatus
export const getLinkModalStatus = (state) => state.modals.getLinkModalStatus
export const changeNameModalStatus = (state) => state.modals.changeNameModalStatus
export const changeLocationModalStatus = (state) => state.modals.changeLocationModalStatus
export default modalsSclice.reducer
