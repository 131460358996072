import {createSlice} from '@reduxjs/toolkit'

export const tokenSclice = createSlice({
    name: 'token',
    initialState: {
        token: null,
    },
    reducers: {
        setTokenState: (state, action) => {
            state.token = action.payload
        },
    },
})

export const {
    setTokenState,
} = tokenSclice.actions
export const token = (state) => state.modals.token
export default tokenSclice.reducer
