import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { closeSubscriptionModal, subscriptionStatus } from "../../Redux/slices/modals";
import { FaClipboard } from 'react-icons/fa';
import toast from "react-hot-toast";

export const SubscriptionModal = () => {
    const thisModalStatus = useSelector(subscriptionStatus);
    const dispatch = useDispatch();
    const information = useSelector((state) => state.information);

    const handleCopy = (url) => {
        navigator.clipboard.writeText(url);
        toast.success('آدرس با موفقیت کپی گردید');
    };

    return (
        <AnimatePresence>
            {thisModalStatus && (
                <>
                    <motion.div
                        className='w-full h-full bg-[rgba(0,0,0,.28)] fixed top-0 left-0 z-50'
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        onClick={() => dispatch(closeSubscriptionModal())}
                    ></motion.div>

                    <motion.div
                        initial={{ top: '-50%' }}
                        animate={{ top: '50%' }}
                        exit={{ top: '-50%' }}
                        className='fixed left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#262431] h-auto xsm:w-[80%] md:w-[38%] flex flex-col z-[101] rounded-md p-6'
                    >
                        <div className='text-white font-black text-xl mb-4 text-center'>
                            دریافت لینک اشتراک
                        </div>
                        <div className='text-white text-sm mb-4 text-center' dir='rtl'>
                            لینک اشتراک چیست؟ شما با وارد کردن این لینک در بخش subscribe کلاینت های مختلف میتوانید بصورت خودکار لیست سرویس های خود را بروز نگه دارید
                        </div>
                        <div className='text-white font-bold text-lg mb-2' dir='rtl'>
                            لینک اشتراک کلش:
                        </div>
                        <div className='bg-[#1e1c29] p-4 rounded-md text-white text-sm flex items-center mb-4 break-all'>
                            <span className="flex-grow">{information.order?.get_subscribers}</span>
                            <button
                                onClick={() => handleCopy(information.order?.get_subscribers)}
                                className='text-blue-500 ml-2'
                            >
                                <FaClipboard size={20} />
                            </button>
                        </div>
                        <div className='text-white font-bold text-lg mb-2' dir='rtl'>
                            لینک اشتراک اندروید و WingsX و Nekoray:
                        </div>
                        <div className='bg-[#1e1c29] p-4 rounded-md text-white text-sm flex items-center mb-4 break-all'>
                            <span className="flex-grow">{information.order?.get_subscribers + '?client=shadowrocket'}</span>
                            <button
                                onClick={() => handleCopy(information.order?.get_subscribers + "?client=shadowrocket")}
                                className='text-blue-500 ml-2'
                            >
                                <FaClipboard size={20} />
                            </button>
                        </div>
                        <button
                            onClick={() => dispatch(closeSubscriptionModal())}
                            className='bg-[#2e74ec] w-full p-2 rounded-md text-white text-lg mt-4'
                        >
                            بازگشت
                        </button>
                    </motion.div>
                </>
            )}
        </AnimatePresence>
    );
};
