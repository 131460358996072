import React, { useState, useRef, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { getLinkModalStatus, closeGetLinkModal } from "../../Redux/slices/modals";
import QRCode from 'qrcode.react';
import toast from "react-hot-toast";

export const GetLinkModal = () => {
    const thisModalStatus = useSelector(getLinkModalStatus);
    const dispatch = useDispatch();

    const information = useSelector((state) => state.information);
    const [showQR, setShowQR] = useState(false);

    const textAreaRef = useRef(null);

    const handleCopy = () => {
        navigator.clipboard.writeText(information.order?.get_config);

        toast.success('کانفیگ با موفقیت کپی گردید');
    };

    const handleShowQR = () => {
        setShowQR(!showQR);
    };

    useEffect(() => {
        if (textAreaRef.current) {
            textAreaRef.current.style.height = 'auto';
            textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 'px';
        }
    }, [information.order?.get_config]);

    return (
        <AnimatePresence>
            {thisModalStatus && (
                <>
                    <motion.div
                        className='w-full h-full bg-[rgba(0,0,0,.28)] fixed top-0 left-0 z-50'
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        onClick={() => dispatch(closeGetLinkModal())}
                    ></motion.div>

                    <motion.div
                        initial={{ top: '-50%' }}
                        animate={{ top: '50%' }}
                        exit={{ top: '-50%' }}
                        className='fixed left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#262431] h-auto xsm:w-[80%] md:w-[38%] flex flex-col z-[101] rounded-md justify-between p-6'
                    >
                        <div className='text-white font-black text-xl mb-4' dir='rtl'>
                            لینک اتصال نیم بها شما:
                        </div>

                        <div className='bg-[#1e1c29] p-4 rounded-md text-white text-sm mb-4'>
                            {showQR ? (
                                <div className="flex justify-center">
                                    <QRCode value={information.order?.get_config} />
                                </div>
                            ) : (
                                <textarea
                                    ref={textAreaRef}
                                    className="w-full h-auto bg-[#1e1c29] text-white outline-none resize-none overflow-hidden"
                                    readOnly
                                    value={information.order?.get_config}

                                />
                            )}
                        </div>

                        <div className='flex justify-between'>
                            <button
                                onClick={handleCopy}
                                className='bg-[#2e74ec] w-[45%] p-2 rounded-md text-white text-lg flex justify-center items-center'
                            >
                                کپی
                            </button>
                            <button
                                onClick={handleShowQR}
                                className='bg-[#2e74ec] w-[45%] p-2 rounded-md text-white text-lg flex justify-center items-center'
                            >
                                {showQR ? "نمایش لینک" : "نمایش QR Code"}
                            </button>
                        </div>
                    </motion.div>
                </>
            )}

        </AnimatePresence>
    );
};