import React from 'react'
import {Progress} from 'antd';

export const PieChart = ({
                             percent,
                             max,
                             title,
                             type,
                             size,
                             gapDegree,
                             trailColor,
                             strokeColor,
                             strokeWidth,
                             titleSize,
                         }) => {

    return (
        <div class="lg:w-1/2 flex flex-col justify-center items-center">
            <div className='services-piechart'>
                <Progress
                    strokeLinecap="butt"
                    type="dashboard"
                    percent={percent}
                    format={(pr) => {
                        if (type && type != 'users') {

                            return `${pr} روز از ${max} روز`
                        } else if (!type) {
                            return `${pr} گیگابایت از ${max} گیگابایت`
                        } else {
                            return <>
                                <img src='/Assets/Images/profile-2user.svg' alt='' className='block m-auto'/>
                                {(pr == 0 && max == 0) ? (
                                    `بی نهایت`
                                ) : (
                                    `${pr} از ${max}`
                                )
                                }
                            </>
                        }
                    }}
                    gapDegree={gapDegree || 150}
                    strokeWidth={strokeWidth || 9}
                    size={size || 120}
                    trailColor={trailColor || '#E4E4E4'}
                    strokeColor={strokeColor || '#6979F8'}
                />
            </div>
            <h5 className={`text-[#F97316] font-bold ${titleSize}`}>{title}</h5>
        </div>
    )
}
