import React, { useEffect, useState } from 'react'
import toast from "react-hot-toast";
import axios from "axios";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import { openChangeLocationModal } from "../Redux/slices/modals";
import { useDispatch } from "react-redux";
import { setTokenState } from "../Redux/slices/token";
import axiosInstance from "../Utils/AxiosInstance";
import { setOrdersSate, setUserSate } from "../Redux/slices/informations";

export const loader = async () => {
    return true
}
export const Home = () => {
    const dispatch = useDispatch()
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [token, setToken] = useState(localStorage.getItem("@token")  || null);
    const [isRequest, setIsRequest] = useState(false);

    const token_params = searchParams.get('token');

    const fetchData = async ({ token_api }) => {
        if (!token_api) {
            toast.error('توکن خود را وارد نمایید.');
            setIsRequest(false)
            return
        }

        try {


            const response = await axiosInstance.get(
                '/token/',
                {
                    headers: {
                        'Authorization': `Token ${token_api}`
                    }
                }
            );

            if (response.status === 200) {
                toast.success(response.data.message);
                dispatch(setTokenState(token_api))
                localStorage.setItem("@token", token_api)
                navigate('/dashboard');
            }
        } catch
        (error) {
            if (error.response && error.response.status === 401) {
                toast.error('توکن شما معتبر نمی باشد، لطفا توکن خود را بررسی کنید.');
            } else {
                toast.error('خطایی رخ داده است، با پشتیبانی تماس بگیرید.');
            }
        }
    }
        ;

    useEffect(() => {
        if (token_params && !isRequest) {
            setIsRequest(true)
            setToken(token_params)
            fetchData({ token_api: token_params });
        }
    }, [token_params]);


    return (
        <main class="px-10 flex flex-col items-center gap-20 max-md:gap-10">
            <section
                class="w-full flex lg:flex-row xsm:flex-col-reverse max-lg:gap-10 max-md:gap-6 max-sm:gap-4 justify-between items-center md:pt-20 sm:pt-10">
                <div class="flex flex-col lg:gap-20 md:gap-10 sm:gap-5 xsm:gap-4 max-lg:items-center">
                    <a href=""><p
                        class="xl:text-[90px] lg:text-[60px] md:text-[60px] sm:text-[40px] xsm:text-[36px] text-white font-[modak]"><span
                            class="px-10 rounded-[34px] bg-[#A884FC]">DEY</span> GROUP</p></a>
                    <p class="text-white xl:text-3xl lg:text-xl md:text-2xl max-sm:text-sm font-[mukta] flex gap-1 items-center">
                        <img src="/Assets/Images/smile.svg" alt="" />DEY GROUPs official control panel for users
                    </p>
                    <div class="flex flex-col gap-5">
                        <div class="flex items-center gap-5">
                            <input
                                type="text" placeholder="Enter your token" name="" id=""
                                class="bg-[#211E1E] w-[320px] max-sm:w-5/6 p-3 rounded-md outline-none text-white"
                                value={token}
                                onChange={(e) => (setToken(e.target.value))}
                            />
                            <button
                                onClick={() => (fetchData({ token_api: token }))}
                                class="w-10 h-10 bg-[#F97316] rounded-md flex items-center justify-center"
                            >
                                <img src="/Assets/Images/arrow-right.svg" alt="" class="w-5 h-5" /></button>
                        </div>
                        <p class="text-white ">برای ورود به پنل کاربری کد کاربری خود را وارد
                            کنید</p>
                    </div>
                </div>
                <div class="max-lg:w-[400px] max-md:w-[300px]">
                    <img src="/Assets/Images/main-Image.png" alt="" />
                </div>
            </section>

            <div class="w-full h-px bg-[#292D31]"></div>

            <section class="w-[600px] max-sm:w-full md:py-10 pb-20 flex flex-col items-center gap-10">
                <p class="text-4xl text-white ">هنوز <span
                    class="px-2 rounded-[34px] bg-[#A884FC]">اشتراک</span> ندارید!؟
                </p>
                <p class="text-white text-center text-lg max-sm:text-sm ">برای استفاده از سرویس های
                    "دی" اول باید از طریق ربات تلگرامی ما اقدام به خرید اشتراک کنید</p>
                <Link
                    to="https://t.me/dey_vpnbot"
                    class="py-2 px-5 rounded-[52px] flex items-center text-white  text-xl gap-5 bg-[#F97316] justify-center">
                    <img src="/Assets/Images/Arrow-Left.svg" alt="" />
                    ربات تلگرام
                    <img src="/Assets/Images/Social-Icons.svg" alt="" />
                </Link>
            </section>

        </main>
    )
}
