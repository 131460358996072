import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Header } from './Components/Header'
import { Footer } from './Components/Footer'
import { Submit as SubmitModal } from './Components/Modals/Submit'
import { useSelector } from "react-redux";
import { submitModalStatus } from "./Redux/slices/modals";
import { ChangeService } from "./Components/Modals/ChangeService";
import { Toaster } from "react-hot-toast";
import { ChangeName } from "./Components/Modals/ChangeName";
import { GetLinkModal } from "./Components/Modals/GetLink";
import { SubscriptionModal } from "./Components/Modals/Subscription";
import { ReNewLink } from "./Components/Modals/ReNewlink";
import { IBsngChange } from "./Components/Modals/IBsngChange";
import { UserHistory } from "./Components/Modals/UserHistory";
import { UserOnline } from "./Components/Modals/UserOnline";


export const loader = async () => {
    return true
}

function App() {
    const submitMdStatus = useSelector(submitModalStatus)
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo({ top: 1, behavior: 'smooth' })
    }, [pathname]);

    return (
        <div className="w-full font-['vazirmatn']">
            <Toaster
                position="top-right"
                reverseOrder={false}
            />
            <SubmitModal />
            <ChangeName />
            <IBsngChange />
            <UserHistory />
            <UserOnline />
            <SubscriptionModal />
            <ReNewLink />
            <GetLinkModal />
            <ChangeService />
            {pathname != '/' && <Header />}
            <Outlet />
            <Footer />
        </div>
    );
}


export default App;
